.popup-content {
  margin: auto;
  background: #fff;
  width: 40%;
  padding: 5px;
  border: 1px solid #d7d7d7;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  @media only screen and(max-width: $bp-medium) {
    width: 98%;
  }
}

.popup {
  &__modal {
    padding: 4rem 5.5rem;
    height: 100%;
    @media only screen and(max-width: $bp-medium) {
      padding: 3rem 1rem;
    }
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-column-gap: 1rem;
    margin-bottom: 4rem;
  }

  &__close {
    background-color: transparent;
    outline: none;
    border: none;
    &:hover {
      cursor: pointer;
    }
  }
  &__btn {
    padding: 1.2rem 4rem !important;
  }
  &__btns {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-column-gap: 1rem;
    align-items: center;
    justify-content: end;
  }
  &__btnsCenter {
    display: grid;
    grid-template-columns: repeat(2, min-content);
    grid-column-gap: 1rem;
    align-items: center;
    justify-content: center;
  }
}
