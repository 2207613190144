@import "sass/main.scss";

.topnav {
  grid-column: full-start / full-end;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  // grid-template-columns: repeat(3, 1fr);
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 7rem;
  background-color: $c-white;
  box-shadow: 0px 4px 24px #f3f3f3;
  padding: 0 3rem 0 2rem;
  z-index: 99;

  @media only screen and (max-width: $bp-medium) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 1.5rem;
  }

  &__title {
    display: grid;
    align-items: center;
    justify-items: center;
    @media only screen and (max-width: $bp-medium) {
      display: none;
    }
  }
  &__ul {
    display: grid;
    justify-items: end;
  }
  &__img {
    width: 100%;
    object-fit: contain;
    overflow: hidden;
  }
  &__logos {
    display: grid;
    grid-template-columns: repeat(2, min-content) 1fr;
    grid-column-gap: 1rem;
    align-items: center;
  }
  &__logoSvg {
    display: grid;
    grid-template-columns: min-content 1fr;
    align-items: center;
  }
  &__logo {
    &--name {
      color: $c-blue;
      font-size: $font-20;

      @media only screen and (max-width: $bp-medium) {
        display: none;
      }
    }
  }
  &__logoSvg {
    width: 12rem;
  }
  &__bgbox {
    display: grid;
    align-items: center;
    justify-items: center;
    // background-color: $c-f3;
    background-color: $c-btn-blue;
    color: $c-white;
    width: 4rem;
    height: 4rem;
    overflow: hidden;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    &:hover {
      cursor: pointer;
    }
  }
  &__dropdown {
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    grid-column-gap: 1rem;
    align-items: center;
    justify-items: end;
    &:hover {
      cursor: pointer;
    }
  }

  &__pd {
    padding: 2rem !important;
  }
}

.reports {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: end;
}
.report {
  &__name {
    display: grid;
    grid-template-columns: max-content;
  }
  &__ul {
    background-color: $c-white;
    width: max-content;
    top: 1rem !important;
    box-shadow: 0rem 3rem 1rem rgba($c-black, 0.05);

    &--2 {
      top: 0 !important;
      position: absolute !important;
      right: 23.5rem !important;
    }
  }
  &__dropdown {
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-column-gap: 1rem;
    align-items: center;
    justify-items: end;
    &:hover {
      cursor: pointer;
    }
  }

  &__visible__none{
    visibility: 0;
    opacity: 0;
  }
}

