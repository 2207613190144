@import "sass/main.scss";

.listings {
  grid-column: col-start 2 / full-end;
  background-color: $c-f3;
  margin: 7rem 2rem 2rem 2rem;
  @media only screen and (max-width: $bp-medium) {
    grid-column: full-start / full-end;
    margin: 7rem 1rem 2rem 1rem;
  }
  &__loaderPosition {
    position: relative;
  }
}
.listing {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.22);
  border-radius: 7px;
  padding: 3rem;
  background-color: $c-white;
  margin: 2rem 0;

  &__arvhiveHeader {
    margin-bottom: 3rem;
  }
  &__header {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 2rem;
    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: 1fr;
    }

    &--box {
      display: grid;
      grid-template-columns: 17rem repeat(2, max-content);
      justify-content: end;
      grid-gap: 1rem;

      @media only screen and (max-width: $bp-medium) {
        grid-template-columns: 1fr;
      }
    }
  }
  &__right {
    display: grid;
    align-items: center;
    justify-content: end;
  }
  &__tabs {
    margin: 2rem 0 3rem 0;
  }
  @media only screen and (max-width: $bp-medium) {
    &__tab {
      display: grid;
      grid-auto-flow: column;
      overflow-x: scroll;
    }
  }
  &__item {
    display: inline-block;
    padding: 1rem 2rem;
    margin-right: 1rem;
    margin-bottom: 2rem;
    color: $op-2;
    border-radius: 10rem;
    -webkit-border-radius: 10rem;
    -moz-border-radius: 10rem;
    -ms-border-radius: 10rem;
    -o-border-radius: 10rem;
    box-shadow: 0 0 0.1rem rgba(117, 131, 142, 0.04),
      0 0.2rem 0.4rem rgba(52, 60, 68, 0.16);

    @media only screen and (max-width: $bp-medium) {
      display: grid;
      grid-template-columns: max-content;
    }
    &--name {
      font-size: $font-15;
    }
    &:hover {
      background-color: $c-btn-blue;
      color: $c-white;
      cursor: pointer;
    }
    // &--active {
    //   background-color: $c-btn-blue;
    //   color: $c-white;
    //   cursor: pointer;
    // }
  }
  &__breadcrum {
    margin: 2rem 0 0 0;
    display: grid;
    align-items: center;
    justify-items: end;
  }
  &__actions {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 0.7rem;
  }

  &__workingStatus {
    width: 1.2rem;
  }
}

// .rdt_TableCol {
//   overflow: visible;
//   width: 20rem;
// }

// .iAwKFK {
//   min-height: 3rem !important;
// }
