.rstm-tree-item-group {
  list-style-type: none;
  padding-left: 0;
  border-top: none;
  text-align: left;
  width: 100%;
}
.rstm-tree-item-group:focus {
  border: none !important;
  outline: none !important;
  list-style-type: none !important;
  list-style: none !important;
}
.rstm-tree-item {
  border-bottom: none;
  box-shadow: none;
}
.rstm-tree-item--active {
  color: white;
  box-shadow: 0 0 0.1rem rgba(117, 131, 142, 0.04),
    0 0.2rem 0.4rem rgba(52, 60, 68, 0.16);
  background: $c-btn-blue;
  border-bottom: none;
}
.rstm-tree-item:hover {
  color: white;
  background: $c-btn-blue;
  border-bottom: none;
}
