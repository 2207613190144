.tooltip {
  display: grid;
  justify-self: center;
  align-self: center;
  text-align: center;
  font-size: $font-14;
}
[role="tooltip"].popup-content {
  width: auto;
  box-shadow: rgba($c-black, 0.16) 0px 0px 3px;
  // background-color: $c-black;
  // color: $c-white;
}

// second custom tooltip

.tooltip {
  position: relative;
  display: inline-block;
  // margin-right: 1rem;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: max-content;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 0.5rem 1.2rem;
  // font-size: 1rem;
  /*Positionthetooltip*/
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  border-radius: 0.3rem;
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  -ms-border-radius: 0.3rem;
  -o-border-radius: 0.3rem;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
