.u-center-text {
  text-align: center !important;
}
// margin bottom
.mb-s-sm {
  margin-bottom: 1rem !important;
}

.mb-sm {
  margin-bottom: 2rem !important;
}

.mb-md {
  margin-bottom: 3rem !important;
}
.mb-md-4 {
  margin-bottom: 4rem !important;
}

.mb-bg {
  margin-bottom: 6rem !important;
}
.mb-hub {
  margin-bottom: 10rem !important;
}
// margin right
.mr-ss-sm {
  margin-right: 0.5rem !important;
}
.mr-s-sm {
  margin-right: 1rem !important;
}
.mr-sm {
  margin-right: 2rem !important;
}
// margin left
.ml-s-sm {
  margin-left: 1rem !important;
}
.ml-sm {
  margin-left: 2rem !important;
}
.ml-sm {
  margin-left: 2rem !important;
}
// margin to
.mt-03-sm {
  margin-top: 0.3rem !important;
}
.mt-s-sm {
  margin-top: 1rem !important;
}
.mt-sm {
  margin-top: 2rem !important;
}
.mt-md {
  margin-top: 3rem !important;
}
.mt-md-4 {
  margin-top: 4rem !important;
}
.mt-md-6 {
  margin-top: 6rem !important;
}

// text casess
.upper {
  text-transform: uppercase;
}
.cap {
  text-transform: capitalize;
}
.lower {
  text-transform: lowercase;
}

// Font Size

.font-12 {
  font-size: $font-12;
}
.font-14 {
  font-size: $font-14;
}
.font-18 {
  font-size: $font-18;
}
.font-20 {
  font-size: $font-20;
}
.font-24 {
  font-size: $font-24;
}

// width
.w-100 {
  width: 100% !important;
}

// Weight
.weight-400 {
  font-weight: $weight-400;
}
.weight-600 {
  font-weight: $weight-600;
}
.weight-700 {
  font-weight: $weight-700;
}

// border bottom

.border__bottom {
  border-bottom: 1px solid $c-white;

  &--gray {
    color: rgba($c-black, 0.5) !important;
    border-bottom: 1px solid rgba($c-black, 0.5) !important;
  }
}

//
.cursor {
  &:hover {
    cursor: pointer;
  }
}
a {
  color: $c-black;
  text-decoration: none;
  text-decoration: none;
}

// color
.c__aa {
  color: #aaa !important;
}
.c__9b {
  color: #9b9b9b !important;
}
.c__white {
  color: #fff !important;
}
.c__blue {
  color: $c-btn-blue !important;
}

// opacity color
.op-2 {
  color: $op-2 !important;
}

.display__none {
  display: none;
}

.bp__largest__none {
  @media only screen and (max-width: $bp-largest) {
    display: none;
  }
}
.bp__large__none {
  @media only screen and (max-width: $bp-large) {
    display: none;
  }
}
.bp__medium__none {
  @media only screen and (max-width: $bp-medium) {
    display: none;
  }
}
.bp__min__medium__none {
  @media only screen and (min-width: $bp-medium) {
    display: none;
  }
}
.bp__smallLarge__none {
  @media only screen and (max-width: $bp-smallLarge) {
    display: none;
  }
}
.bp__min__smallLarge__none {
  @media only screen and (min-width: $bp-smallLarge) {
    display: none;
  }
}
.bp__small__none {
  @media only screen and (max-width: $bp-small) {
    display: none;
  }
}

.inputs {
  &__icons {
    position: relative;
  }
  &__svg {
    position: absolute;
    top: 35%;
    right: 2rem;
  }
}

.not-allowed {
  &:hover {
    cursor: not-allowed !important;
  }
}
