@import "sass/main.scss";

.dashboard {
  &__cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    grid-gap: 1.5rem;
    margin-top: 2rem;
  }
  &__card {
    display: grid;
    grid-template-columns: min-content 1fr;
    background-color: $c-white;
    overflow: hidden;
    grid-column-gap: 1.5rem;
    box-shadow: 0px 4px 4px rgba(75, 101, 131, 0.13);
    border-radius: 0.7rem;
    -webkit-border-radius: 0.7rem;
    -moz-border-radius: 0.7rem;
    -ms-border-radius: 0.7rem;
    -o-border-radius: 0.7rem;
  }
  &_graph{

    &--line{
      display: grid;
    }
  }
  &__story {
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 1.4rem;

    &--1 {
      background-image: $cricle-grad-blue;
    }
    &--2 {
      background-image: linear-gradient(
        135.85deg,
        #3b82f6 -1.52%,
        #5d5fef 100%
      );
    }
    &--3 {
      background-image: linear-gradient(
        135.85deg,
        $c-green-2 -1.52%,
        $c-green-2 100%
      );
    }
    &--4 {
      background-image: linear-gradient(135deg, #FB9200 0%, #FF1F00 100%);
    }
  }

  &__circle {
    display: grid;
    align-items: center;
    justify-items: center;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;

    &--1 {
      background-image: $cricle-grad-blue;
      background-image: linear-gradient(
        135.85deg,
        #3b82f6 -1.52%,
        #3b82f6 100%
      );
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    }
    &--2 {
      background-image: linear-gradient(
        135.85deg,
        #3b82f6 -1.52%,
        #5d5fef 100%
      );
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    }
    &--3 {
      background-image: $c-green-2;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    }
    &--4 {
      background-image: linear-gradient(135deg, #FB9200 0%, #FF1F00 100%);
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    }
  }
  &__content {
    display: grid;
    justify-items: start;
    align-content: center;
  }
}

.dashboards{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 2rem;


@media only screen and (max-width:$bp-medium){
  grid-template-columns: 1fr;
}

  &__cards{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.22);
    border-radius: 7px;
    padding: 2rem;
    background-color: $c-white;
   
  }
  &__card{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    align-content: start;
    grid-gap: 1rem;
    height: 42rem;
    overflow: scroll;

  }
  &__end{
    display: grid;
    justify-self: end;
    align-self: center;
  }
  &__underline{
    display: grid;
    grid-column: 1/-1;
    border-bottom: 1px solid rgba($c-black, 0.1);
  }
  &__dots{
    display: grid;
    align-items: center;
    grid-template-columns: min-content 1fr;
    grid-column-gap: 1rem;
  }
  &__dot{
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;

    &--blue{
      background-color: $c-btn-blue;
    }
    &--gray{
      background-color: $c-aaa;
    }
    &--black{
      background-color: $c-black;
    }
}
  &__blueBg{
    color: $c-btn-blue;
  }
  &__subFull{
    display: grid;
    grid-column: 1/-1;
    font-size: $font-12;
  }
  &__gray{
    color: $c-aaa;
    font-size: $font-12;
  }
}