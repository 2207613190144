.multiselectContainer {
  // To change css for multiselect (Widthheightetc..)
  // height: 1rem;
}
.searchBox {
  //Tochangesearchboxelementlookborder: none;
  font-size: $font-16;
  margin-left: 1rem;
}
.searchWrapper {
//   padding: 5px;
//   position: relative;
  border-radius: 0.7rem;
  -webkit-border-radius: 0.7rem;
  -moz-border-radius: 0.7rem;
  -ms-border-radius: 0.7rem;
  -o-border-radius: 0.7rem;
}
.inputField {
  // To change input field position or margin
  // margin: 2rem;
}
.chips {
  // To change css chips(Selected options)
  //   background: red;
}
.optionContainer {
  // To change css for option container
//   border: 2px solid red;
}
.option {
  // To change css for dropdown options
  //   color: $color-primary-black;
}
.groupHeading {
  // To chanage group heading style
  // color: red;
}
.multiSelectContainer li:hover {
  background: $c-l-blue;
}
.chip {
  padding: .4rem 1.2rem;
  background: $c-d-blue;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: .5rem;
  display: inline-flex;
  align-items: center;
  font-size: $font-16;
  color: $c-white;
  border-radius: 10rem;
  -webkit-border-radius: 10rem;
  -moz-border-radius: 10rem;
  -ms-border-radius: 10rem;
  -o-border-radius: 10rem;
}
