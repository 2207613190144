@import "sass/main.scss";

.enterEmail {
  &__story {
    height: 100vh;
    grid-column: full-start / col-end 4;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    background-color: $c-white;

    @media only screen and (max-width: $bp-medium) {
      height: 8rem;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      width: 100% !important;
      grid-row: 1/2;
      grid-column: 1/-1;
    }
  }
  &__content {
    height: 100vh;
    overflow-y: scroll;
    grid-column: col-start 5 / full-end;
    background-image: linear-gradient(147.98deg, $c-l-blue 0%, $c-d-blue 100%);
    padding: 2rem 0;
    display: grid;
    align-items: center;
    justify-items: center;
    color: $c-white;

    @media only screen and (max-width: $bp-medium) {
      grid-column: full-start/full-end;
      grid-row: 2/3;
      height: calc(100vh - 8rem);
    }
  }

  &__img {
    width: 100%;
    grid-row: 1/6;
    grid-column: 1/6;
    object-fit: cover;
    object-position: 100%;
    display: grid;
    align-self: center;
    align-content: center;
    z-index: 20;
    padding: 2rem 0 2rem 6rem;

    @media only screen and (max-width: $bp-medium) {
      display: none;
    }

    &--2 {
      width: 25rem;
      margin-top: 2rem;

      @media only screen and (max-width: $bp-medium) {
        width: 13rem;
        margin-top: 1rem;
      }
    }
  }

  &__text {
    grid-row: 6/7;
    grid-column: 1/7;
    z-index: 100;
    padding: 2rem 6rem;
    display: grid;
    align-items: center;

    @media only screen and (max-width: $bp-medium) {
      padding: 1rem;
    }

    &--size{
      font-weight: 700;
      font-family: $font-display;
      line-height: 1.2;
      font-size: 4rem;
      @media only screen and (max-width: $bp-medium) {
        font-size: $font-12;
      }
    }
  }

  &__box {
    display: grid;
    align-items: center;
    justify-items: start;
    width: 33rem !important;
    @media only screen and (max-width: $bp-small){
      width: 30rem !important;
    }
  }
  &__w32 {
    width: 32.5rem !important;
    @media only screen and (max-width: $bp-small){
      width: 30rem !important;
    }
  }
  &__btn {
    width: 32.5rem !important;
    padding: 1.8rem 5rem !important;
    background-color: $c-btn-blue;
    box-shadow: 0px 0px 1px rgba(117, 131, 142, 0.04),
      0px 2px 4px rgba(52, 60, 68, 0.16);
    border-radius: 0.3rem !important;
    -webkit-border-radius: 0.3rem !important;
    -moz-border-radius: 0.3rem !important;
    -ms-border-radius: 0.3rem !important;
    -o-border-radius: 0.3rem !important;
    color: $c-white;
    font-weight: $weight-700;
    @media only screen and (max-width: $bp-small){
      width: 30rem !important;
    }
  }
  &__link {
    color: $c-white;
  }
}
