@import "sass/main.scss";

.profileInfo {
  &__access {
    padding: 0.7rem 2rem;
    border-radius: 10rem;
    -webkit-border-radius: 10rem;
    -moz-border-radius: 10rem;
    -ms-border-radius: 10rem;
    -o-border-radius: 10rem;

    &__readOnly {
      background-color: rgba($c-green-2, 0.2);
      color: $c-green-2;
    }
    &__fullAccess {
      background-color: rgba($c-btn-blue, 0.2);
      color: $c-btn-blue;
    }
  }
  &__classes {
    margin-top: 1rem;
    margin-right: 1rem;
    padding: 0.3rem 1.8rem;
    border-radius: 10rem;
    -webkit-border-radius: 10rem;
    -moz-border-radius: 10rem;
    -ms-border-radius: 10rem;
    -o-border-radius: 10rem;
    font-size: $font-14;
    background-color: $c-btn-blue;
    color: $c-white;
    display: inline-block;
  }
}

.companyProfiles {
  grid-column: full-start / full-end;
  background-color: $c-f3;
  margin-top: 8rem;
}
.companyClasses {
  grid-column: full-start / full-end;
  background-color: $c-f3;
}
.companyClasse {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.22);
  background-color: $c-white;
  border-radius: 7px;
  padding: 6rem;
  margin: 0 3rem 6rem 3rem;
}

.companyProfile {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.22);
  background-color: $c-white;
  border-radius: 7px;
  padding: 6rem;
  margin: 3rem;

  @media only screen and (max-width: $bp-medium) {
    padding: 1rem 1.5rem;
    margin: 3rem 0;

    box-shadow: none;
    background-color: transparent;
  }
  &__header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
    align-items: start;
    margin-bottom: 3rem;

    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: 1fr;
      grid-row-gap: 2rem;
      grid-column-gap: 1rem;
    }

    &-end {
      justify-self: end;
      @media only screen and (max-width: $bp-medium) {
        justify-self: start;
      }
    }
  }
  &__breadcrum {
    margin: 3rem 3rem 0 0;
    display: grid;
    align-items: center;
    justify-items: end;

    @media only screen and (max-width: $bp-medium) {
      margin: 3rem 1rem 0 0;
    }
  }
  &__labels {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;

    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: 1fr;
    }
  }
  &__access {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 3rem;
    justify-content: end;
    align-content: start;
  }
  &__green {
    color: $c-green-2;
    margin-left: 1rem;
  }
  &__blue {
    color: $c-btn-blue;
    margin-left: 1rem;
  }
  &__mtb {
    margin: 3rem 0;
  }
  &__mt {
    margin-top: 3rem;
  }
  &__links {
    display: grid;
    justify-items: end;
    margin-top: 4rem;
  }
  &__btn {
    width: 13rem;
    text-align: center;
  }
}
