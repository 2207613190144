@import "sass/main.scss";

.otp {
  &__story {
    height: 100vh;
    grid-column: full-start / col-end 4;

    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    background-color: $c-white;

    @media only screen and (max-width: $bp-medium) {
      height: 8rem;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      width: 100% !important;
      grid-row: 2/3;
      grid-column: 1/-1;
    }
  }
  &__content {
    height: 100vh;
    grid-column: col-start 5 / full-end;
    background-image: linear-gradient(147.98deg, $c-l-blue 0%, $c-d-blue 100%);

    display: grid;
    align-items: center;
    justify-items: center;
    color: $c-white;
    @media only screen and (max-width: $bp-medium) {
      grid-column: full-start/full-end;
      height: calc(100vh - 8rem);
      padding: 1.5rem;
    }
  }
  &__img {
    width: 111%;
    height: 100%;
    // object-fit: contain;
    // object-position: 100%;
    grid-row: 1/6;
    grid-column: 1/7;
    z-index: 20;

    @media only screen and (max-width: $bp-medium) {
      display: none;
    }

    &--2 {
      grid-row: 6/7;
      grid-column: 1/6;
      margin-left: 2.3rem;

      @media only screen and (max-width: $bp-medium) {
        width: 20rem;
        display: grid;
        align-items: center;
        justify-items: center;
        margin-bottom: 1rem;
      }
    }
  }
  &__box {
    display: grid;
    align-items: center;
    justify-items: start;
    // width: 33rem !important;
  }
  &__btn {
    width: 32.5rem !important;
    padding: 1.8rem 5rem !important;
    background-color: $c-btn-blue;
    box-shadow: 0px 0px 1px rgba(117, 131, 142, 0.04),
      0px 2px 4px rgba(52, 60, 68, 0.16);
    border-radius: 0.3rem !important;
    -webkit-border-radius: 0.3rem !important;
    -moz-border-radius: 0.3rem !important;
    -ms-border-radius: 0.3rem !important;
    -o-border-radius: 0.3rem !important;
    color: $c-white;
    font-weight: $weight-700;
  }
  &__link {
    color: $c-white;
  }
  &__resends {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 32.5rem !important;
  }
  &__resend {
    // @media only screen and (max-width: $bp-medium){
    //   width: 100% !important;
    // }
    display: grid;
    justify-content: end;
    align-items: center;
    font-size: $font-12;
    &:hover {
      cursor: pointer;
    }
  }
}
