@import "sass/main.scss";

.sidenavs {
  grid-column: full-start / col-end 1;

  min-height: calc(100vh - 7rem);
  top: 7rem;
  position: fixed;
  // position: relative;
  width: 15%;
  box-shadow: 0px 4px 24px $c-f3;
  background-color: $c-white;
  text-transform: capitalize;
  box-shadow: 0px 4px 24px #f3f3f3;
  padding-top: 4rem;
  // z-index: 44;
  // overflow-y: scroll;
  // overflow-x: scroll;

  @media only screen and (max-width: $bp-medium) {
    grid-column: full-start / full-end;
    min-height: auto;
    display: grid;
    grid-auto-flow: column;
    position: relative;
    padding-top: 0;
    padding-left: 1rem;
    width: 100%;
    margin: 2rem 0;
    background-color: transparent;
    overflow-y: scroll;
    overflow-x: scroll;
    max-width: 100% !important;
  }
}
.sidenav {
  // position: fixed;

  @media only screen and (max-width: $bp-medium) {
    height: auto;
    display: grid;
    grid-auto-flow: column;
  }

  &__archives {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    justify-items: start;
    grid-gap: 1rem;
    padding: 0 2rem 2rem 2rem;
    color: $c-aaa;

    @media only screen and (max-width: $bp-medium) {
      padding: 0 2.4rem;
    }
  }
  &__archive {
    display: grid;
    align-items: center;
    justify-items: center;
    width: 4rem;
    height: 4rem;
    background-color: rgba($c-btn-blue, 0.2);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }
  &__li {
    display: grid;
    grid-template-columns: min-content 1fr;
    align-items: center;
    justify-items: start;
    grid-gap: 1rem;
    padding: 1rem 2rem;
    border: 1px solid transparent;

    &--2 {
      padding: 2rem;
    }
    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: min-content max-content;
      border-radius: 10rem;
      -webkit-border-radius: 10rem;
      -moz-border-radius: 10rem;
      -ms-border-radius: 10rem;
      -o-border-radius: 10rem;
      border: 1px solid $c-black;
      margin-right: 1rem;
      padding: 0.6rem 2.4rem;
    }
    &:hover {
      background-color: $c-btn-blue;
      cursor: pointer;
      color: $c-white;
    }
  }
  &__active {
    background-color: $c-btn-blue;
    border: 1px solid $c-btn-blue;
    color: $c-white;
  }
  &__active &__svg {
    fill: $c-white;
  }
  &__svg {
    fill: black;
    width: 1.6rem;
    height: 1.6rem;
  }
  &__li:hover &__svg {
    fill: $c-white;
  }
}
