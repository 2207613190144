disabled {
  pointer-events: none;
  opacity: 0.3;
}

.btn {
  &,
  // &:link,
  &:visited {
    -webkit-box-sizing: border-box;
    -webkit-appearance: none;
    outline: none;
    text-decoration: none;
    font-size: inherit;
    padding: 0.6rem 2rem;
    border: 1px solid transparent;
    transition: all 0.2s;
    cursor: pointer;
    border-radius: 1.3rem;
    -webkit-border-radius: 1.3rem;
    -moz-border-radius: 1.3rem;
    -ms-border-radius: 1.3rem;
    -o-border-radius: 1.3rem;
    box-shadow: 0 0 0.1rem rgba(117, 131, 142, 0.04),
      0 0.2rem 0.4rem rgba(52, 60, 68, 0.16);
  }
  &__brd {
    display: grid;
    align-content: center;
    align-self: center;
    border-radius: 0.7rem !important;
    -webkit-border-radius: 0.7rem !important;
    -moz-border-radius: 0.7rem !important;
    -ms-border-radius: 0.7rem !important;
    -o-border-radius: 0.7rem !important;
  }

  &__blue {
    background-color: $c-btn-blue;
    color: $c-white;
  }
  &__red {
    background-color: $c-red;
    color: $c-white;
  }
}

.btn__border {
  background-color: transparent;
  border: 1px solid $c-black;
  color: $c-black;
  padding: 0.6rem 4rem;
  display: grid;
  align-content: center;
  align-self: center;
  box-shadow: 0 0 0.1rem rgba(117, 131, 142, 0.04),
    0 0.2rem 0.4rem rgba(52, 60, 68, 0.16);
}

.btn-svg {
  display: grid;
  grid-template-columns: max-content min-content;
  grid-column-gap: 0.8rem;
  align-items: center;
  justify-self: start;
  box-shadow: 0 0 0.1rem rgba(117, 131, 142, 0.04),
    0 0.2rem 0.4rem rgba(52, 60, 68, 0.16);
}
