.screen__loader__container {
  position: fixed;
  top: 0;
  left: 0;
  background: #00000050;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
}
.screen__loader__position {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
}
.grid__loader__position {
  position: absolute;
  top: calc(50%);
  left: calc(50%);
}
// Button Loader
.btn__loader__box {
  display: grid;
  align-items: center;
  justify-content: center;
  width: 39px;
  height: 22px;
}
.btn__loader {
  border: 2px solid $c-white;
  border-radius: 10rem;
  border-top: 2px solid $c-black;
  width: 2rem;
  height: 2rem;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
// Full Screen Loader
.screen__loader {
  border: 2px solid $c-white;
  border-radius: 10rem;
  border-top: 2px solid $c-black;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

.screen__loader__box {
  display: grid;
  align-items: center;
  justify-content: center;
  height: calc(100vh);
}
.grid__loader__box {
  display: grid;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 7rem);
}
