@import "sass/main.scss";

.info{
    &__container{
        padding: 5rem;
    }
    &__btns{
        display: grid;
        grid-auto-flow: column;
        justify-items: end;
        justify-content: end;
        grid-column-gap: 2rem;
        margin-top: 5rem;

        @media only screen and (max-width: $bp-small){
            grid-auto-flow: row;
            justify-content: start;
            justify-items: center;
            grid-row-gap: 2rem;
        }
    }
    &__btn_orange{
        background-color: $c-orange !important;
        border:  5.5px solid $c-orange !important;
        color: $c-white !important;
    }
}